import {Component, Input} from '@angular/core';
import {HomePage} from '../../pages/home/home.page';
import {MenuPage} from '../../pages/menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-toolbar-large',
	templateUrl: './toolbar-large.component.html',
	styleUrls: ['toolbar-large.component.scss']
})
export class ToolbarLargeComponent {
	@Input()
	hideMenu = false;
	@Input()
	allowNavigation = true;
	environment = environment;

	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	home() {
		if (!this.allowNavigation) {
			return;
		}
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
