import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
	selector: 'app-select-language-popover',
	templateUrl: './select-language-popover.component.html',
	styleUrls: ['./select-language-popover.component.scss']
})
export class SelectLanguagePopoverComponent implements OnInit {
	@Input() lang: string;
	constructor(private popover: PopoverController) {}

	ngOnInit() {}
	selectLanguage(value: string) {
		this.popover.dismiss(value);
	}
}
