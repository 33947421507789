import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';
import {Location} from '@angular/common';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage {
	static url = 'privacy';

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private location: Location
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}

	goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
