import {Component, EventEmitter, Input, Output} from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {WorkingHoursModalComponent} from '../working-hours-modal/working-hours-modal.component';
import {ModalController} from '@ionic/angular';
import {
	PreorderType,
	PreorderTypeArray
} from '../../../smoothr-web-app-core/enums/PreorderType';
import moment, {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {
	getSlots,
	isVenueOpen,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';

@Component({
	selector: 'app-venue-suggestion',
	templateUrl: './venue-suggestion.component.html',
	styleUrls: ['venue-suggestion.component.scss']
})
export class VenueSuggestionComponent {
	environment = environment;
	@Input() type: PreorderType;
	@Output() venuePick = new EventEmitter<{venue: Venue; type: PreorderType}>();
	isVenueOpen = false;
	pt = PreorderType;
	@Input() isShopView: boolean = null;
	@Input()
	overridePostalDelivery = false;
	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;
	@Input()
	selected = false;
	@Input()
	isMap = false;
	ptArray = PreorderTypeArray.filter(it => {
		return it !== PreorderType.PARK_COLLECT && it !== PreorderType.INSIDE;
	});
	deliveryCompanies = ['lieferando', 'uber_eats', 'wolt'];

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}

	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.acceptsDelivery = false;
		this.acceptsTakeaway = false;
		this.acceptsInside = false;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.firstInsideTime = null;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}
		this.isVenueOpen = this.isVenueOpenFunction(venue);
		getSlots(
			venue,
			OrderType.PREORDER,
			PreorderType.TAKE_AWAY,
			null,
			0,
			false,
			3
		).then(slots => {
			this.acceptsTakeaway =
				slots.length > 0 && venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
			if (this.acceptsTakeaway) {
				this.firstTakeAwayTime = this.timeToText(venue, moment(slots[0].time));
			}
		});

		this.acceptsDelivery =
			this.venue &&
			this.venue?.deliveryHours?.length > 0 &&
			this.venue.deliveryEnabled &&
			this.venue.isServiceActivated &&
			this.venue.isPublished;
	}
	isVenueOpenFunction(venue: Venue) {
		return (
			venue &&
			venue.openingHours.length > 0 &&
			TimeUtils.doesHoursMatchNow(venue.openingHours)
		);
	}

	selectVenue(venue: Venue, type: PreorderType) {
		if (!venue.isServiceActivated) {
			return;
		}
		if (!this.accepts(type)) {
			return;
		}
		this.venuePick.emit({venue, type});
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			case PreorderType.INSIDE:
				return this.acceptsInside;
			default:
				return false;
		}
	}

	openModal(venue: Venue) {
		WorkingHoursModalComponent.show(this.modalCtrl, venue, this.type);
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs(
					(moment().minutes() % venue.slot.interval) - venue.slot.interval
				) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) %
					venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday =
				now.dayOfYear() === relevantTime.dayOfYear() &&
				now.year() === relevantTime.year();
			return (
				(isToday
					? ''
					: this.translate.instant('venue_suggestion.tomorrow') + ' ') +
				relevantTime.format('HH:mm')
			);
		} else {
			return null;
		}
	}
	isVenueOpenFunc() {
		return this.isVenueOpen
			? this.translate.instant('venue_suggestion.open')
			: this.translate.instant('venue_suggestion.close');
	}
	openLink(url: string) {
		window.open(url, '_blank');
	}
	checkIfSocialLinksExist(venue: any): {link?: string; name?: string} {
		return venue?.externalDeliveryService[0]?.link ||
			venue?.externalDeliveryService[0]?.name
			? venue?.externalDeliveryService[0]
			: {};
	}
	navigateToSite(url: string) {
		window.open(url, '_blank');
	}
	showLink(data: any) {
		return data?.googlePlacesId ?? '';
	}
	checkIfWoltExist(venue: any) {
		return venue?.area && venue?.area?.includes('wolt') ? venue?.area : '';
	}
}
