import { AfterViewInit, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import Table from 'src/smoothr-web-app-core/models/Table';
import { MatSnackBar } from '@angular/material/snack-bar';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AppComponent } from 'src/app/app.component';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-select-employee-table',
	templateUrl: './select-table-employee.component.html',
	styleUrls: ['./select-table-employee.component.scss'],
})
export class SelectTableEmployeeComponent implements AfterViewInit {
	order: Order;
	venue: Venue;
	tables: Table[] = [];
	selectedTable: Table;
	loading = false;
	constructor(private modalCtrl: ModalController, private snackbarCtrl: MatSnackBar) {}

	static async show(modalCtrl: ModalController, order: Order, venue: Venue): Promise<Table> {
		const modal = await modalCtrl.create({
			component: SelectTableEmployeeComponent,
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',

			componentProps: {
				order,
				venue,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngAfterViewInit() {
		this.getTables();
	}
	async getTables() {
		this.loading = true;
		try {
			this.tables = (await Api.getTablesByVenue(this.venue._id)).data.filter(
				table => table.number !== 'system_table' && table.number !== 'preorder_table'
			);
			if (this.order && this.order.tableNumber !== 'preorder_table') {
				const foundTable = this.tables.find(table => table.number === this.order.tableNumber);
				if (foundTable) {
					this.selectedTable = foundTable;
				}
			}
			this.loading = false;
		} catch (e) {
			this.snackbarCtrl.open('Fehler beim Abrufen der Tabelle, bitte versuchen Sie es später', null, {
				duration: 2000,
			});
			this.loading = false;
		}
	}

	selectItem(item: Table) {
		this.selectedTable = item;
	}
	async goBack() {
		await this.modalCtrl.dismiss();
	}
	async continue() {
		await this.modalCtrl.dismiss(this.selectedTable);
	}
}
