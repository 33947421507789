export default class Address {
	street: string;
	number: string;
	postalCode: string;
	state: string;
	country: string;
	city: string;
	lng: number;
	lat: number;
	placeId?: string;
	displayName?: string;
}
