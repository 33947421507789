import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {calculateGeoDistance} from 'src/smoothr-web-app-core/utils/maps-utils';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {numberD, numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
	@Input() set venue(value: Venue) {
		this._venue = value;

		this.validate();
	}
	@Input() set order(value: Order) {
		this._order = value;

		this.validate();
	}
	@Input() tip: number = 0;
	ou = OrderUtils;
	numberToCurrency = numberToCurrency;
	freeDeliveryPrice = 0;
	differenceToMvo: number;
	_venue: Venue;

	_order: Order;
	constructor() {}

	ngOnInit(): void {
		this.validate();
	}

	get hasArticles(): boolean {
		return (this._order?.orderedArticles?.length ?? 0) > 0;
	}

	validate() {
		if (!this._order || !this._venue) {
			return;
		}
		const result = OrderUtils.validateOrder(this._venue, this._order);
		this.differenceToMvo = result.movDifference;
		console.log(this.differenceToMvo);
		this.calculateDeliveryMinPriceAndFreeDelivery(this._venue, this._order);
		return true;
	}

	calculateDeliveryMinPriceAndFreeDelivery(venue: Venue, _order: Order) {
		if (!venue || !_order) {
			return;
		}
		if (venue.deliveryByRadius) {
			const distance = calculateGeoDistance(
				_order.preorder.lat,
				_order.preorder.lng,
				venue.location.coordinates[1],
				venue.location.coordinates[0]
			);
			console.log('DISTANCE', distance);
			if (!venue.movAndFee.byRadius || venue.movAndFee.byRadius.length === 0) {
				console.log('No movAndFee.byRadius taking defaults');
				return null;
			}

			const byRadius = venue.movAndFee.byRadius.reduce((prev, curr) => {
				return curr.radius >= distance && (prev?.radius ?? 0) < distance
					? curr
					: prev;
			}, null);

			if (!byRadius) {
				console.log('No specific fees found taking defaults');
				return null;
			}
			console.log('BY RADIUS', byRadius);
			const feeTotalFreeDelivery = byRadius.fees.find(
				it => numberD(it.fee) == 0
			);
			if (feeTotalFreeDelivery) {
				this.freeDeliveryPrice = numberD(feeTotalFreeDelivery.from);
			}
		}
	}
}
