import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const maloaCustomerGroup = 'maloa';
export const customerGroup = maloaCustomerGroup;

const supportEmail = {
	maloa: 'maloa@smoothr.de'
};
const firebaseConfig = {
	maloa: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:06ce90a246832118e0fe06',
		measurementId: 'G-Z8LRRZ3HG4'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDdq2l1BDp3XRHtgdBcXaJ33epqQOhJELY',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment
};
