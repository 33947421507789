import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Subject, interval} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-available-driver-modal',
	templateUrl: './available-driver-modal.component.html',
	styleUrls: ['./available-driver-modal.component.scss']
})
export class AvailableDriverModalComponent implements OnInit {
	private destroy$ = new Subject<void>();
	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {
		interval(5000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dismiss();
			});
	}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: AvailableDriverModalComponent,
			componentProps: {},
			mode: 'ios',
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		await sleep(100);
		return response.data;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	ngOnDestroy() {
		// Unsubscribe from the timer when the component is destroyed
		this.destroy$.next();
		this.destroy$.complete();
	}
}
