export enum PromoCodeType {
	ABSOLUTE = 'absolute',
	RELATIVE = 'relative',
	DELIVERY_FEE = 'delivery_fee',
	BOGO = 'bogo',
	REWARD = 'reward',
	FREE_ARTICLE = 'free_article',
	RELATIVE_ARTICLE = 'relative_article',
	ABSOLUTE_ARTICLE = 'absolute_article'
}
