import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import Localization from '../models/Localization';

@Pipe({name: 'langString'})
export class LangStringPipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: Localization, lang?: string): string {
		if (!value) {
			return '';
		}
		if (!lang) {
			return value.de;
		}
		if (!value[this.translate.getBrowserLang()]) {
			return value.de;
		} else {
			return value[this.translate.getBrowserLang()];
		}
	}
}
