export class AuthLoginResponse {
	customerGroup: string;
	email: string;
	id: string;
	linkedCustomerGroup: string;
	name: string;
	permissions: string[];
	role: string;
	roles: string[];
	token: string;
	venue: string[];
	venues: string[];
}
