import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {environment} from '../../../environments/environment';
import {
	getAvailability,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss']
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Output() openInfo = new EventEmitter<Article>();
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;

	constructor() {}

	isHidden(article: Article): boolean {
		return (
			this.preorderType &&
			!getAvailability(article, OrderType.PREORDER, this.preorderType)
		);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(
			selectedOption => selectedOption.group === this.optionGroup._id
		);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(
			article => article._id === this.selected[indexSelection].article._id
		);
	}

	onSelectionChange(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	price(option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		return price;
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(
				di => di === DisplayIdentifier.fullPrice
			) >= 0
		);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25')
			? 'deposit25'
			: '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15')
			? 'deposit15'
			: '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8')
			? 'deposit8'
			: '';

		return deposit15 || deposit25 || deposit8;
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens)
			return Object.values(article?.compound?.allergens)?.find(
				it => it === true
			);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives)
			return Object.values(article?.compound?.additives)?.find(
				it => it === true
			);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article & {nutritions?: any}) {
		if (!article?.nutritions) {
			return;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
