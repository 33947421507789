import {Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {AlertController, IonSearchbar, ModalController} from '@ionic/angular';
import {ModalInfoComponent} from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import {AppComponent} from '../../app.component';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrderPage} from '../order/order.page';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {
	SelectVenueModalComponent,
	SelectVenueModalResult
} from '../../components/select-venue-modal/select-venue-modal.component';
import {
	axiosErrorToMessage,
	getAvailability,
	getSlots,
	getSlotsByOrder,
	isVenueOpen,
	numberD,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {OnlyNextDayModalComponent} from '../../components/only-next-day-modal/only-next-day-modal.component';
import {ScanQrModal} from 'src/app/components/scan-qr/scan-qr.component';
import Venue from '../../../smoothr-web-app-core/models/Venue';

import {HomePage} from '../home/home.page';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import Address from 'src/smoothr-web-app-core/models/Address';
import {AllowGpsModalComponent} from 'src/app/components/allow-gps-modal/allow-gps-modal.component';
import {CheckDeliveryRadiusModalComponent} from 'src/app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import {map} from 'rxjs/operators';
import {MapPage} from '../map/map.page';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss']
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, {static: false}) toOrderButton;
	@ViewChild('categoryList', {static: false}) categoryList;
	@ViewChild('articleContent', {static: false}) articleList;
	@ViewChild(IonSearchbar, {static: false}) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	isValid = false;
	orderUtils = OrderUtils;
	differenceToMvo: number;
	preorderType = PreorderType;
	attempt = 1;
	venueOpen = true;
	venuePanic = false;
	environment = environment;
	searchBarOpen: boolean = false;
	order$ = this.repository.order.pipe(
		map(order => JSON.parse(JSON.stringify(order)))
	);

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	get index(): number {
		return this.categories.findIndex(
			category => category._id === this.selectedCategory._id
		);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_cancel'),
					null,
					{
						duration: 2000
					}
				);
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(
					this.translate.instant('order.payment_fail'),
					null,
					{
						duration: 5000
					}
				);
				break;
		}
		this.loadVenue();
		this.openQrScan(this.venue);
	}
	ngAfterViewInit() {
		this.reloadPanicAndOpening();
	}
	async loadVenue() {
		this.loading = true;
		try {
			this.repository.venue.emit(
				await this.repository.getVenue(
					this.order?.venue ?? environment.customerGroup + '_master'
				)
			);
		} catch (e) {
			console.error(e);
			this.loading = false;
		}
		this.reloadPanicAndOpening();
		this.loading = false;
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
		return;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 &&
						event.detail.currentY < array[index + 1]) ||
						index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(
				category => category._id === this.selectedCategory._id
			);
		}
		if (prevIndex === 0 && categoryIndex === -1 && this.categories[0]) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(categoryIndex);
			return;
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategory = this.categories[categoryIndex];
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		if (!this.venueOpen || this.venuePanic) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_page.venue_panic_or_closed'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		if (
			this.order.preorder.type === PreorderType.INSIDE &&
			this.order.tableNumber === 'preorder_table'
		) {
			this.openSelectTableModal();
			return;
		}
		if (!this.order) {
			await OnlyNextDayModalComponent.show(this.modalCtrl);
			const result = await SelectVenueModalComponent.show(this.modalCtrl);
			if (result === undefined) {
				this.loading = false;
				return;
			}
			const venue = await this.repository.getVenue(result.venue._id);

			if (
				!result.preorderType &&
				!this.order?.table &&
				this.order?.preorder.type !== PreorderType.INSIDE
			) {
				return;
			} else {
				this.repository.createOrder(
					result.venue,
					result.address,
					result.preorderType,
					null
				);
				const allArticles = [];
				venue.articleCategories.forEach(cat =>
					allArticles.push(...cat.articles)
				);
				const articleInVenue = allArticles.find(
					art =>
						art._id === item._id ||
						(art.masterId === item.masterId && item.masterId) ||
						art.masterId === item._id
				);
				this.loading = false;
				if (!articleInVenue) {
					return;
				}
				item = articleInVenue;
			}
		}
		await this.openSelectedProduct(item);
	}

	async openQrScan(venue: Venue) {
		if (
			this.order &&
			this.order.tableNumber === 'preorder_table' &&
			this.order.preorder.type === PreorderType.INSIDE
		) {
			const table = await ScanQrModal.show(this.modalCtrl, venue);
			await sleep(200);
			if (!table) {
				this.snackbarCtrl.open(
					this.translate.instant('menu_page.no_table'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
			try {
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				await SelectTableModalComponent.show(this.modalCtrl, this.order);
			} catch (e) {
				console.error(e);
				this.snackbarCtrl.open(
					this.translate.instant('menu_page.venue_panic_or_closed'),
					null,
					{
						duration: 2000
					}
				);
				return;
			}
		}
	}
	async onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
		if (!this.searchTerm) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(0);
			await this.scrollArticleListTo(0);
		}
	}

	async openSelectedProduct(item: Article) {
		const res = await ModalInfoComponent.show(
			this.modalCtrl,
			item,
			this.order.preorder?.type
		);
		if (res) {
			OrderUtils.addToOrder(this.order, res, this.analytics);
			this.repository.order.emit(this.order);
		}
	}
	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm =
			this.searchTerm && this.searchTerm !== ''
				? this.searchTerm.toLocaleLowerCase()
				: null;
		console.log(this.venue.articleCategories);
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));

				cat.articles = cat.articles.filter(article => {
					const available = getAvailability(
						article,
						OrderType.PREORDER,
						this.order?.preorder?.type
					);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase()
						];
						return (
							available &&
							keys
								.map(key => key.indexOf(lowerSearchTerm))
								.find(result => result >= 0) !== undefined
						);
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
		return;
	}
	validate() {
		if (!this.venue || !this.order) {
			this.isValid = false;
			return;
		}
		const mov =
			this.order.preorder?.type === PreorderType.DELIVERY
				? numberD(this.venue.movDelivery)
				: 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder?.type,
			this.order.terminalorder?.type
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.loadVenue();
			return;
		}
		this.reloadCategories();
		this.reloadPanicAndOpening();
		this.selectedCategory =
			this.venue && this.categories.length > 0 ? this.categories[0] : null;
		this.validate();
	}

	async reloadPanicAndOpening() {
		this.loading = true;
		if (!this.venue) {
			this.venueOpen = false;
			this.venuePanic = false;
			this.loading = false;
			return;
		}
		if ((this.order?.type ?? OrderType.STANDARD) === OrderType.STANDARD) {
			this.venueOpen = isVenueOpen(this.venue);
		} else {
			this.venueOpen =
				(await getSlotsByOrder(this.venue, this.order, 0, false, 3)).length > 0;
		}
		this.venuePanic =
			!this.venue?.isServiceActivated ||
			this.venue?.panicEndAt.isAfter(moment());
		this.loading = false;
		console.log({open: this.venueOpen, panic: this.venuePanic});
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo &&
				!this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		await sleep(100);

		this.categoryList.nativeElement.scrollTo(
			this.categoryList.nativeElement.children[index].offsetLeft - 25,
			0
		);
		await sleep(100);
	}

	private async scrollArticleListTo(index: number) {
		await this.articleList.scrollToPoint(
			0,
			this.articleList.el.children[index].offsetTop + 1,
			300
		);
	}
	goHome() {
		if (!this.authLogin) {
			HomePage.navigate(this.router);
		}
	}
	async openSelectTableModal() {
		const table = await ScanQrModal.show(this.modalCtrl, this.venue);
		if (!table) {
			return;
		}
		try {
			this.order.tableNumber = table.number;
			this.order.table = table._id;
			this.repository.order.emit(this.order);
			await SelectTableModalComponent.show(this.modalCtrl, this.order);
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(
				this.translate.instant('menu_page.venue_panic_or_closed'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
	}
	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}
	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = false;
		this.reloadCategories();
	}

	trackFunc(index: number, item) {
		return item._id;
	}

	async toMap() {
		await this.router.navigateByUrl(MapPage.url);
	}
}
