import {Component, EventEmitter, Input, Output} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {environment} from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import {
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

import {ModalController} from '@ionic/angular';
import {AllergenInfoModalComponent} from '../allergen-info-modal/allergen-info-modal.component';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.html',
	styleUrls: ['item-selectable-article.component.scss']
})
export class ItemSelectableArticleComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Output() openInfo = new EventEmitter();
	@Input() currency: string;
	numberToCurrency = numberToCurrency;

	constructor(private modalCtrl: ModalController) {}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}
	async showAllergens(article: Article) {
		await AllergenInfoModalComponent.show(this.modalCtrl, article);
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25')
			? 'deposit25'
			: '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15')
			? 'deposit15'
			: '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8')
			? 'deposit8'
			: '';

		return deposit15 || deposit25 || deposit8;
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens)
			return Object.values(article?.compound?.allergens)?.find(
				it => it === true
			);
		else return null;
	}
	isAddit(article: Article) {
		if (article?.compound?.additives)
			return Object.values(article?.compound?.additives)?.find(
				it => it === true
			);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article & {nutritions?: any}) {
		if (!article?.nutritions) {
			return;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}
}
