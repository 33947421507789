import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MapsUtils } from 'src/maps/utils/maps';
import Address from 'src/smoothr-web-app-core/models/Address';
import Venue from 'src/smoothr-web-app-core/models/Venue';

@Component({
	selector: 'app-map-search-venue',
	templateUrl: './map-search-venue.component.html',
	styleUrls: ['./map-search-venue.component.scss'],
})
export class MapSearchVenueComponent  {
	searchTerm = '';
	@Input() showSearch: boolean = true;
	@Input() address: Address = null;
	@Output() selectVenue = new EventEmitter<Venue | null>();
	@Output() selectVenueToDelivery = new EventEmitter<Venue | null>();
	@Output() changeSlide = new EventEmitter<void>();
	@Output() changesAddress = new EventEmitter<Address | null>();

	@ViewChild(IonInput, { static: true })
	inputField: IonInput;
	loading = false;
	showHint = false;
	loadingAddress = false;
	MapsUtils = MapsUtils;
	predictions: Address[] = [];
	private _showPredictions = false;
	constructor(private snackbarCtrl: MatSnackBar, private cdr: ChangeDetectorRef, public translate: TranslateService) {}

	ngAfterViewInit() {
		new Promise(async () => {
			await this.fillInPlace(null);
			await this.initAutocomplete();
		});
	}
	hidePredictions() {
		setTimeout(() => {
			this._showPredictions = false;
		}, 200);
	}
	async initAutocomplete() {
		console.log(this.inputField);
		await MapsUtils.initAutocomplete(
			this.inputField,
			predictions => {
				this.predictions = predictions;
				this.cdr.detectChanges();
			},
			loading => (this.loadingAddress = loading)
		);
		this.loading = false;
	}
	showPredictions() {
		this._showPredictions = true;
	}

	async executeSearch() {
		console.log('executeSearch()');
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
	}

	resetSearch() {
		console.log('resetSearch()');
		this.searchTerm = '';
		this.predictions = [];
		this.selectVenueToDelivery.emit(null);
		this.selectVenue.emit(null);
		if (this.address) {
      this.changesAddress.emit(null)
		}
		this.showHint = false;
		this.cdr.detectChanges();
	}
	async fillInPlace(address: Address) {
		console.log('fillInPlace()');
		this.loading = true;
		try {
			this.searchTerm = MapsUtils.checkAddress(address);
			this.loading = false;
			if (this.address !== address) {
        this.changesAddress.emit(address)
			}
			this.changeSlide.emit();
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}
	async loadPlaceFunc(prediction: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		await this.fillInPlace(prediction);
		this.loading = false;
		this.cdr.detectChanges();
	}
}
