import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const maloaCustomerGroup = 'maloa';
export const customerGroup = maloaCustomerGroup;

const supportEmail = {
	maloa: 'maloa@smoothr.de'
};
const firebaseConfig = {
	maloa: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:d8efa17338d710fbcbfff0',
		measurementId: 'G-E2NV9L878T'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCHNC0xEc1toWEBpTmvvinDNGy3qYvvR80',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
