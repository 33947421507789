import {OrderStatus} from '../enums/OrderStatus';
import {OrderType} from '../enums/OrderType';
import ArticleGroup from './ArticleGroup';
import {TerminalOrder} from './TerminalOrder';
import {PaymentStatus} from './PaymentStatus';
import {Moment} from 'moment';
import Preorder from './Preorder';
import {PaymentMethod} from '../enums/PaymentMethod';
import PromoCode from './PromoCode';
import {environment} from '../../environments/environment';

export default class Order {
	// tslint:disable-next-line:variable-name
	_id: string;
	createdAt: Moment;
	updatedAt: Moment;
	preparedAt: Moment;
	readyAt: Moment;
	orderAt: Moment;
	asap: boolean;
	estimatedAt: Moment;
	table: string;
	tableNumber: string;
	status = OrderStatus.CREATED;
	type = OrderType.STANDARD;
	preorder?: Preorder;
	orderedArticles: ArticleGroup[] = [];
	code: string;
	isPayed: boolean;
	terminalorder?: TerminalOrder;
	affiliateCode: string;
	venue: string;
	venueName: string;
	statusNote: string;
	currency: string;
	paymentId: string;
	method: PaymentMethod;
	paymentStatus: PaymentStatus;
	paymentMethod: PaymentMethod;
	transferred = false;
	referenceOrder: string = null;
	flavor = environment.flavor;
	promoCode: PromoCode | any;
	claimedLoyaltyPoints: number;
	loyaltyNumber: string;
	userUid: string;
}
