import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-only-next-day-modal',
	templateUrl: './only-next-day-modal.component.html',
	styleUrls: ['./only-next-day-modal.component.scss']
})
export class OnlyNextDayModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: OnlyNextDayModalComponent,
			cssClass: 'only-next-day-modal auto-height',
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		await modal.onDidDismiss();
	}

	ngOnInit() {}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
}
