import {environment} from './../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-approve-order-modal',
	templateUrl: './approve-order-modal.component.html',
	styleUrls: ['./approve-order-modal.component.scss']
})
export class ApproveOrderModalComponent {
	environment = environment;
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: ApproveOrderModalComponent,
			cssClass: 'only-next-day-modal auto-height',
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		await modal.onDidDismiss();
		await sleep(200);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}
}
