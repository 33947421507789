import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {InfoModalComponent} from '../info-modal/info-modal.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SelectLanguagePopoverComponent} from '../select-language-popover/select-language-popover.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-toolbar-logo-menu',
	templateUrl: './toolbar-logo-menu.component.html',
	styleUrls: ['./toolbar-logo-menu.component.scss']
})
export class ToolbarLogoMenuComponent {
	@Input() showCross = false;
	@Input() showDailogNavigate = false;
	@Input() venue: Venue | null = null;
	@Input() order: Order | null = null;
	@Output() close = new EventEmitter<void>();
	@Output() toMap = new EventEmitter<void>();
	environment = environment;
	selectedLanguage = this.translate.currentLang;

	opened = false;
	constructor(
		private router: Router,
		public repository: RepositoryService,
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private popover: PopoverController
	) {
		this.selectedLanguage = this.translate.currentLang;
		this.translate.onLangChange.subscribe(v => {
			if (v) {
				this.selectedLanguage = v?.lang;
			}
		});
	}
	async home() {
		if (this.showDailogNavigate) {
			const result = await InfoModalComponent.show(this.modalCtrl, {
				info: this.translate.instant('navigate_pop_up.title'),
				mainButton: this.translate.instant('navigate_pop_up.ok'),
				closeButton: this.translate.instant('navigate_pop_up.no')
			});
			if (result) {
				window.open('https://maloa.com/', '_blank');
			}
		} else {
			HomePage.navigate(this.router, this.repository);
		}
	}
	closeWindow() {
		this.close.emit();
	}
	selectedImage() {
		return '../../../assets/flag/flag_' + this.selectedLanguage + '.png';
	}
	async openPopoverLanuage(ev: any) {
		this.opened = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			event: ev,
			translucent: true,
			showBackdrop: false,
			componentProps: {
				lang: this.translate.currentLang
			}
		});
		popover.onWillDismiss().then(v => {
			console.log(v);
			if (v.data) {
				this.translate.setDefaultLang(v.data);
				this.translate.use(v.data);
				this.selectedLanguage = v.data;
			}

			this.opened = false;
			// this.visible = false;
		});
		await popover.present();

		// this.visible = false;
		return;
	}

	venueInfo() {
		this.toMap.emit();
	}
}
