import Article from './Article';

export default class ArticleOption {
	group: string;
	article: Article;
	quantity: number;
	dependencyNumber: number;
	dependsOn: string;
	dependency: string;
}
