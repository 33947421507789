import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from '../components/toolbar/toolbar.component';
import {IonicModule} from '@ionic/angular';
import {ArticleOptionGroupFlavorComponent} from '../components/article-option-group-flavor/article-option-group-flavor.component';
import {ItemSelectableArticleComponent} from '../components/item-selectable-article/item-selectable-article.component';
import {ToOrderButtonComponent} from '../components/to-order-button/to-order-button.component';
import {OrderListComponent} from '../components/order-list/order-list.component';
import {ToggleButtonGroupComponent} from '../components/toggle-button-group/toggle-button-group.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MyOrderComponent} from '../components/my-order/my-order.component';
import {QRCodeModule} from 'angular2-qrcode';
import {ItemViewComponent} from '../components/item-view/item-view.component';
import {ArticleOptionGroupSingleComponent} from '../components/article-option-group-single/article-option-group-single.component';
import {VenueSuggestionComponent} from '../components/venue-suggestion/venue-suggestion.component';
import {HomePopoverComponent} from '../components/home-popover/home-popover.component';
import {ArticleOptionGroupCheckboxComponent} from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import {ArticleOptionGroupOverlayComponent} from '../components/article-option-group-overlay/article-option-group-overlay.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {createTranslateLoader} from '../app.module';
import {BurgerBtnComponent} from '../components/burger-btn/burger-btn.component';
import {ToolbarSmallComponent} from '../components/toolbar-small/toolbar-small.component';
import {ArticleOptionGroupMultipleComponent} from '../components/article-option-group-multiple/article-option-group-multiple.component';
import {RouterModule} from '@angular/router';
import {ToolbarLargeComponent} from '../components/toolbar-large/toolbar-large.component';
import {KlOverlayOptionItemComponent} from '../components/kl-overlay-option-item/kl-overlay-option-item.component';
import {OrderContentComponent} from '../components/order-content/order-content.component';
import {PromoCodeComponent} from '../components/promo-code/promo-code.component';
import {PaymentMethodsDisplayComponent} from '../components/payment-methods-display/payment-methods-display.component';
import {CheckDeliveryRadiusModalComponent} from '../components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import {CustomAddressModalComponent} from '../components/custom-address-modal/custom-address-modal.component';
import {SelectVenueModalComponent} from '../components/select-venue-modal/select-venue-modal.component';
import {AllowGpsModalComponent} from '../components/allow-gps-modal/allow-gps-modal.component';
import {TipComponent} from '../components/tip/tip.component';
import {LangStringPipe} from '../../smoothr-web-app-core/pipes/lang-string.pipe';
import {TagComponent} from '../components/tag/tag.component';
import {OrderPageToolbarComponent} from '../components/order-page-toolbar/order-page-toolbar.component';
import {ScanQrModal} from '../components/scan-qr/scan-qr.component';
import {ZXingScannerModule} from 'src/scanner/public_api';
import {SelectTableModalComponent} from '../components/select-table-modal/select-table-modal.component';
import {OrderListPayComponent} from '../components/order-list-pay/order-list-pay.component';
import {SlotsSelectorComponent} from '../components/slots-selector/slots-selector.component';
import {QuantitySelectorComponent} from '../components/quantity-selector/quantity-selector.component';
import {MenuModalComponent} from '../components/menu-modal/menu-modal.component';
import {NoteArticleModalComponent} from '../components/note-article-modal/note-article-modal.component';
import {AccountToolbarComponent} from '../components/account-toolbar/account-toolbar.component';
import {HomePreorderTypeButtonComponent} from '../components/home-preorder-type-button/home-preorder-type-button.component';
import {ImageToolbarComponent} from '../components/image-toolbar/image-toolbar.component';
import {PickTableComponent} from '../components/pick-table/pick-table.component';
import {SelectTableEmployeeComponent} from 'src/employee-module/components/select-table-employee/select-table-employee.component';
import {ApproveOrderModalComponent} from '../components/approve-order-modal/approve-order-modal.component';
import {ToolbarLogoComponent} from '../components/toolbar-logo/toolbar-logo.component';
import {MapVenueComponent} from 'src/maps/components/map-venue/map-venue.component';
import {MapSearchVenueComponent} from 'src/maps/components/map-search-venue/map-search-venue.component';
import {LangObservablePipe} from 'src/smoothr-web-app-core/pipes/lang-obervable.pipe';
import {ChipTableComponent} from '../components/chip-table/chip-table.component';
import {PreorderTypeChipComponent} from '../components/preorder-type-chip/preorder-type-chip.component';
import {AddCustomTipComponent} from '../components/add-custom-tip/add-custom-tip.component';
import {AppRestorePasswordModalComponent} from '../components/app-restore-password-modal/app-restore-password-modal.component';
import {InfoModalComponent} from '../components/info-modal/info-modal.component';
import {AvailableDriverModalComponent} from '../components/available-driver-modal/available-driver-modal.component';
import {ProgressBarComponent} from '../components/progress-bar/progress-bar.component';
import {SelectLanguagePopoverComponent} from '../components/select-language-popover/select-language-popover.component';
import {AskedRegisterModalComponent} from '../components/asked-register-modal/asked-register-modal.component';
import {ToolbarLogoMenuComponent} from '../components/toolbar-logo-menu/toolbar-logo-menu.component';
import {AllergenInfoModalComponent} from '../components/allergen-info-modal/allergen-info-modal.component';

@NgModule({
	declarations: [
		ToolbarComponent,
		ToolbarLargeComponent,
		ToolbarSmallComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupOverlayComponent,
		ArticleOptionGroupMultipleComponent,
		KlOverlayOptionItemComponent,
		BurgerBtnComponent,
		OrderContentComponent,
		PromoCodeComponent,
		PaymentMethodsDisplayComponent,
		CheckDeliveryRadiusModalComponent,
		CustomAddressModalComponent,
		SelectVenueModalComponent,
		AllowGpsModalComponent,
		OrderPageToolbarComponent,
		SelectTableModalComponent,
		OrderListPayComponent,
		ScanQrModal,
		TagComponent,
		TipComponent,
		LangStringPipe,
		SlotsSelectorComponent,
		QuantitySelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AccountToolbarComponent,
		HomePreorderTypeButtonComponent,
		ImageToolbarComponent,
		PickTableComponent,
		SelectTableEmployeeComponent,
		ApproveOrderModalComponent,
		ToolbarLogoComponent,
		MapSearchVenueComponent,
		MapVenueComponent,
		LangObservablePipe,
		ChipTableComponent,
		PreorderTypeChipComponent,
		AddCustomTipComponent,
		AppRestorePasswordModalComponent,
		InfoModalComponent,
		AvailableDriverModalComponent,
		ProgressBarComponent,
		SelectLanguagePopoverComponent,
		AskedRegisterModalComponent,
		ToolbarLogoMenuComponent,
		AllergenInfoModalComponent
	],
	exports: [
		ToolbarComponent,
		ScanQrModal,
		ToolbarLargeComponent,
		ToolbarSmallComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupOverlayComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ItemSelectableArticleComponent,
		KlOverlayOptionItemComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		OrderContentComponent,
		PromoCodeComponent,
		CheckDeliveryRadiusModalComponent,
		CustomAddressModalComponent,
		SelectVenueModalComponent,
		AllowGpsModalComponent,
		OrderPageToolbarComponent,
		SelectTableModalComponent,
		OrderListPayComponent,
		LangStringPipe,
		TagComponent,
		SlotsSelectorComponent,
		QuantitySelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AccountToolbarComponent,
		HomePreorderTypeButtonComponent,
		ImageToolbarComponent,
		PaymentMethodsDisplayComponent,
		TipComponent,
		PickTableComponent,
		SelectTableEmployeeComponent,
		ApproveOrderModalComponent,
		ToolbarLogoComponent,
		MapSearchVenueComponent,
		MapVenueComponent,
		LangObservablePipe,
		ChipTableComponent,
		PreorderTypeChipComponent,
		AddCustomTipComponent,
		AppRestorePasswordModalComponent,
		InfoModalComponent,
		AvailableDriverModalComponent,
		ProgressBarComponent,
		SelectLanguagePopoverComponent,
		AskedRegisterModalComponent,
		ToolbarLogoMenuComponent,
		AllergenInfoModalComponent
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		MatSnackBarModule,
		ZXingScannerModule,
		QRCodeModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		RouterModule,
		ReactiveFormsModule
	]
})
export class SharedModuleModule {}
